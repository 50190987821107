import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Header from './components/Header';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorFallback from './components/ErrorFallback';

// Lazy load pages
const HomePage = lazy(() => import('./pages/HomePage'));
const WebDevelopment = lazy(() => import('./pages/WebDevelopment'));
const MobileDevelopment = lazy(() => import('./pages/MobileDevelopment'));
const CloudSolutions = lazy(() => import('./pages/CloudSolutions'));
const DataAnalytics = lazy(() => import('./pages/DataAnalytics'));
const ApiDevelopment = lazy(() => import('./pages/ApiDevelopment'));
const ItConsulting = lazy(() => import('./pages/ItConsulting'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));

// Lazy load hire pages
const HireReact = lazy(() => import('./pages/hire/React'));
const HireVue = lazy(() => import('./pages/hire/Vue'));
const HireAngular = lazy(() => import('./pages/hire/Angular'));
const HireNextJs = lazy(() => import('./pages/hire/NextJs'));
const HireTypeScript = lazy(() => import('./pages/hire/TypeScript'));
const HireNodeJS = lazy(() => import('./pages/hire/NodeJS'));
const HirePython = lazy(() => import('./pages/hire/Python'));
const HireJava = lazy(() => import('./pages/hire/Java'));
const HireDotNet = lazy(() => import('./pages/hire/DotNet'));
const HirePHP = lazy(() => import('./pages/hire/PHP'));
const HireFlutter = lazy(() => import('./pages/hire/Flutter'));
const HireIOS = lazy(() => import('./pages/hire/IOS'));
const HireAndroid = lazy(() => import('./pages/hire/Android'));
const HireKotlin = lazy(() => import('./pages/hire/Kotlin'));
const HireReactNative = lazy(() => import('./pages/hire/ReactNative'));
const HireDevOps = lazy(() => import('./pages/hire/DevOps'));
const HireAWS = lazy(() => import('./pages/hire/AWS'));
const HireAzure = lazy(() => import('./pages/hire/Azure'));
const HireGCP = lazy(() => import('./pages/hire/GCP'));
const HireKubernetes = lazy(() => import('./pages/hire/Kubernetes'));
const HireDataScience = lazy(() => import('./pages/hire/DataScience'));
const HireML = lazy(() => import('./pages/hire/MachineLearning'));
const HireDataEngineer = lazy(() => import('./pages/hire/DataEngineer'));
const HireAI = lazy(() => import('./pages/hire/AI'));
const HireAnalytics = lazy(() => import('./pages/hire/Analytics'));
const HireUIUX = lazy(() => import('./pages/hire/UIUX'));
const HireQA = lazy(() => import('./pages/hire/QA'));
const HireTechnicalArchitect = lazy(() => import('./pages/hire/TechnicalArchitect'));
const HireProjectManager = lazy(() => import('./pages/hire/ProjectManager'));
const HireSecurityEngineer = lazy(() => import('./pages/hire/SecurityEngineer'));

// Initialize React Query client with optimized settings
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data stays fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Cache persists for 30 minutes
      retry: 2,
      refetchOnWindowFocus: false,
      suspense: true
    }
  }
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="min-h-screen bg-white">
          <Header />
          <ScrollToTop />
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              {/* Main Routes */}
              <Route path="/" element={<HomePage />} />
              <Route path="/services/web-development" element={<WebDevelopment />} />
              <Route path="/services/mobile-development" element={<MobileDevelopment />} />
              <Route path="/services/cloud-solutions" element={<CloudSolutions />} />
              <Route path="/services/data-analytics" element={<DataAnalytics />} />
              <Route path="/services/api-development" element={<ApiDevelopment />} />
              <Route path="/services/it-consulting" element={<ItConsulting />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<ContactUs />} />

              {/* Frontend Development Routes */}
              <Route path="/hire-us/reactjs" element={<HireReact />} />
              <Route path="/hire-us/vuejs" element={<HireVue />} />
              <Route path="/hire-us/angular" element={<HireAngular />} />
              <Route path="/hire-us/nextjs" element={<HireNextJs />} />
              <Route path="/hire-us/typescript" element={<HireTypeScript />} />

              {/* Backend Development Routes */}
              <Route path="/hire-us/nodejs" element={<HireNodeJS />} />
              <Route path="/hire-us/python" element={<HirePython />} />
              <Route path="/hire-us/java" element={<HireJava />} />
              <Route path="/hire-us/dotnet" element={<HireDotNet />} />
              <Route path="/hire-us/php" element={<HirePHP />} />

              {/* Mobile Development Routes */}
              <Route path="/hire-us/flutter" element={<HireFlutter />} />
              <Route path="/hire-us/ios" element={<HireIOS />} />
              <Route path="/hire-us/android" element={<HireAndroid />} />
              <Route path="/hire-us/kotlin" element={<HireKotlin />} />
              <Route path="/hire-us/react-native" element={<HireReactNative />} />

              {/* DevOps & Cloud Routes */}
              <Route path="/hire-us/devops" element={<HireDevOps />} />
              <Route path="/hire-us/aws" element={<HireAWS />} />
              <Route path="/hire-us/azure" element={<HireAzure />} />
              <Route path="/hire-us/gcp" element={<HireGCP />} />
              <Route path="/hire-us/kubernetes" element={<HireKubernetes />} />

              {/* Data & AI Routes */}
              <Route path="/hire-us/data-science" element={<HireDataScience />} />
              <Route path="/hire-us/machine-learning" element={<HireML />} />
              <Route path="/hire-us/data-engineer" element={<HireDataEngineer />} />
              <Route path="/hire-us/ai" element={<HireAI />} />
              <Route path="/hire-us/analytics" element={<HireAnalytics />} />

              {/* Specialized Roles Routes */}
              <Route path="/hire-us/ui-ux" element={<HireUIUX />} />
              <Route path="/hire-us/qa" element={<HireQA />} />
              <Route path="/hire-us/architect" element={<HireTechnicalArchitect />} />
              <Route path="/hire-us/project-manager" element={<HireProjectManager />} />
              <Route path="/hire-us/security" element={<HireSecurityEngineer />} />

              {/* Fallback route for unmatched paths */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
          <Footer />
        </div>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

// Handle stored path from direct navigation
const storedPath = localStorage.getItem('path');
if (storedPath) {
  localStorage.removeItem('path');
  window.history.replaceState(null, '', storedPath);
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, Code2, ChevronDown } from 'lucide-react';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHireUsOpen, setIsHireUsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const location = useLocation();

  const hireUsOptions = [
    {
      category: "Frontend Development",
      items: [
        { title: 'Hire React.js Developers', href: '/hire-us/reactjs' },
        { title: 'Hire Angular Developers', href: '/hire-us/angular' },
        { title: 'Hire Vue.js Developers', href: '/hire-us/vuejs' },
        { title: 'Hire Next.js Developers', href: '/hire-us/nextjs' },
        { title: 'Hire TypeScript Developers', href: '/hire-us/typescript' }
      ]
    },
    {
      category: "Backend Development",
      items: [
        { title: 'Hire Node.js Developers', href: '/hire-us/nodejs' },
        { title: 'Hire Python Developers', href: '/hire-us/python' },
        { title: 'Hire Java Developers', href: '/hire-us/java' },
        { title: 'Hire .NET Developers', href: '/hire-us/dotnet' },
        { title: 'Hire PHP Developers', href: '/hire-us/php' }
      ]
    },
    {
      category: "Mobile Development",
      items: [
        { title: 'Hire Flutter Developers', href: '/hire-us/flutter' },
        { title: 'Hire iOS Developers', href: '/hire-us/ios' },
        { title: 'Hire Android Developers', href: '/hire-us/android' },
        { title: 'Hire Kotlin Developers', href: '/hire-us/kotlin' },
        { title: 'Hire React Native Developers', href: '/hire-us/react-native' }
      ]
    },
    {
      category: "DevOps & Cloud",
      items: [
        { title: 'Hire DevOps Engineers', href: '/hire-us/devops' },
        { title: 'Hire AWS Developers', href: '/hire-us/aws' },
        { title: 'Hire Azure Developers', href: '/hire-us/azure' },
        { title: 'Hire GCP Developers', href: '/hire-us/gcp' },
        { title: 'Hire Kubernetes Engineers', href: '/hire-us/kubernetes' }
      ]
    },
    {
      category: "Data & AI",
      items: [
        { title: 'Hire Data Scientists', href: '/hire-us/data-science' },
        { title: 'Hire ML Engineers', href: '/hire-us/machine-learning' },
        { title: 'Hire Data Engineers', href: '/hire-us/data-engineer' },
        { title: 'Hire AI Developers', href: '/hire-us/ai' },
        { title: 'Hire Analytics Experts', href: '/hire-us/analytics' }
      ]
    },
    {
      category: "Specialized Roles",
      items: [
        { title: 'Hire UI/UX Designers', href: '/hire-us/ui-ux' },
        { title: 'Hire QA Engineers', href: '/hire-us/qa' },
        { title: 'Hire Technical Architects', href: '/hire-us/architect' },
        { title: 'Hire Project Managers', href: '/hire-us/project-manager' },
        { title: 'Hire Security Engineers', href: '/hire-us/security' }
      ]
    }
  ];

  const serviceOptions = [
    { title: 'Web Development', href: '/services/web-development' },
    { title: 'Mobile Development', href: '/services/mobile-development' },
    { title: 'Cloud Solutions', href: '/services/cloud-solutions' },
    { title: 'Data Analytics', href: '/services/data-analytics' },
    { title: 'API Development', href: '/services/api-development' },
    { title: 'IT Consulting', href: '/services/it-consulting' }
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleHomeClick = (e: React.MouseEvent) => {
    if (location.pathname === '/') {
      e.preventDefault();
      scrollToTop();
    }
  };

  return (
    <header className="fixed w-full bg-white/95 backdrop-blur-sm z-50 shadow-sm">
      <nav className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <Link 
            to="/" 
            className="flex items-center space-x-2"
            onClick={handleHomeClick}
          >
            <Code2 className="h-8 w-8 text-indigo-600" />
            <span className="text-xl font-bold text-gray-900">Cognixs</span>
          </Link>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link 
              to="/" 
              className="text-gray-600 hover:text-indigo-600 transition-colors"
              onClick={handleHomeClick}
            >
              Home
            </Link>

            {/* Services Dropdown */}
            <div className="relative">
              <button 
                className="flex items-center space-x-1 text-gray-600 hover:text-indigo-600 transition-colors"
                onClick={() => setIsServicesOpen(!isServicesOpen)}
                onMouseEnter={() => setIsServicesOpen(true)}
                onMouseLeave={() => setIsServicesOpen(false)}
              >
                <span>Services</span>
                <ChevronDown className="h-4 w-4" />
              </button>

              <div 
                className={`absolute left-0 top-full mt-2 w-64 bg-white rounded-xl shadow-xl border border-gray-100 transition-all duration-200 ${
                  isServicesOpen ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'
                }`}
                onMouseEnter={() => setIsServicesOpen(true)}
                onMouseLeave={() => setIsServicesOpen(false)}
              >
                <div className="p-4">
                  <div className="grid grid-cols-1 gap-2">
                    {serviceOptions.map((option, index) => (
                      <Link
                        key={index}
                        to={option.href}
                        className="text-gray-700 hover:text-indigo-600 hover:bg-indigo-50 px-3 py-2 rounded-lg transition-colors"
                        onClick={() => setIsServicesOpen(false)}
                      >
                        {option.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <Link to="/about" className="text-gray-600 hover:text-indigo-600 transition-colors">About</Link>
            <a href="#projects" className="text-gray-600 hover:text-indigo-600 transition-colors">Projects</a>
            
            {/* Hire Us Dropdown */}
            <div className="relative">
              <button 
                className="flex items-center space-x-1 text-gray-600 hover:text-indigo-600 transition-colors"
                onClick={() => setIsHireUsOpen(!isHireUsOpen)}
                onMouseEnter={() => setIsHireUsOpen(true)}
                onMouseLeave={() => setIsHireUsOpen(false)}
              >
                <span>Hire Us</span>
                <ChevronDown className="h-4 w-4" />
              </button>

              <div 
                className={`absolute right-0 top-full mt-2 w-[90vw] max-w-6xl bg-white rounded-xl shadow-xl border border-gray-100 transition-all duration-200 ${
                  isHireUsOpen ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'
                }`}
                onMouseEnter={() => setIsHireUsOpen(true)}
                onMouseLeave={() => setIsHireUsOpen(false)}
                style={{ 
                  transform: 'translateX(30%)',
                  maxHeight: '80vh',
                  overflowY: 'auto'
                }}
              >
                <div className="p-8">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {hireUsOptions.map((category, index) => (
                      <div key={index} className="space-y-4">
                        <h3 className="font-semibold text-gray-900 text-lg border-b border-gray-200 pb-2">
                          {category.category}
                        </h3>
                        <div className="grid grid-cols-1 gap-2">
                          {category.items.map((item, itemIndex) => (
                            <Link
                              key={itemIndex}
                              to={item.href}
                              className="text-gray-700 hover:text-indigo-600 hover:bg-indigo-50 px-3 py-1 rounded-lg transition-colors"
                              onClick={() => setIsHireUsOpen(false)}
                            >
                              {item.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <Link 
              to="/contact" 
              className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Contact Us
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 bg-white border-t shadow-lg">
            <div className="flex flex-col space-y-4 px-6 py-4">
              <Link 
                to="/"
                className="text-gray-600 hover:text-indigo-600 transition-colors"
                onClick={(e) => {
                  handleHomeClick(e);
                  setIsMenuOpen(false);
                }}
              >
                Home
              </Link>
              
              {/* Mobile Services Section */}
              <div className="space-y-2">
                <div className="font-medium text-gray-800">Services</div>
                <div className="grid grid-cols-1 gap-2 pl-4">
                  {serviceOptions.map((option, index) => (
                    <Link
                      key={index}
                      to={option.href}
                      className="text-gray-600 hover:text-indigo-600 transition-colors"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {option.title}
                    </Link>
                  ))}
                </div>
              </div>

              <Link 
                to="/about" 
                className="text-gray-600 hover:text-indigo-600 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                About
              </Link>
              <a href="#projects" className="text-gray-600 hover:text-indigo-600 transition-colors">Projects</a>
              
              {/* Mobile Hire Us Section */}
              <div className="space-y-4">
                {hireUsOptions.map((category, index) => (
                  <div key={index} className="space-y-2">
                    <div className="font-medium text-gray-800">{category.category}</div>
                    <div className="grid grid-cols-1 gap-2 pl-4">
                      {category.items.map((item, itemIndex) => (
                        <Link
                          key={itemIndex}
                          to={item.href}
                          className="text-gray-600 hover:text-indigo-600 transition-colors"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          {item.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <Link 
                to="/contact"
                className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors text-center"
                onClick={() => setIsMenuOpen(false)}
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}
